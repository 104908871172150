import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
} from "@material-tailwind/react";
import React, { ReactNode, useState } from "react";
import { SIDEBAR_ITEMS } from "constants/sidebar";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ChevronDoubleRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import { ReactComponent as HideSidebarIcon } from "assets/svg/hideSIdebar.svg";
import {
  ModuleResponse,
  useGetChapterQuestions,
  useGetUserCareer,
} from "api/services";
import Skeleton from "./UI/Skeleton";

interface LayoutProps {
  secondarySidebar?: ReactNode;
}

function Icon({ id, open }: any) {
  return (
    <ChevronDownIcon
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform mr-2 text-white`}
    />
  );
}

interface Chapter {
  id: string;
  label: string;
  path: string;
}

interface SidebarModuleProps {
  open: number;
  handleOpen: (id: number) => void;
  id: number;
  percentage: number;
  title: string;
  items: Chapter[];
  onItemSelected?: (selectedItem: string) => void;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
}

const SidebarModule: React.FC<SidebarModuleProps> = ({
  open,
  handleOpen,
  id,
  percentage,
  title,
  items,
  onItemSelected,
  className = "",
  headerClassName = "",
  bodyClassName = "",
}) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [selectedChapter, setSelectedChapter] = useState<Chapter>();
  const navigate = useNavigate();
  const { data: questions, refetch } = useGetChapterQuestions(55);

  const handleItemClick = (label: string, path: string, item: any) => {
    console.log(item, "+++");

    setSelectedItem(label);
    if (onItemSelected) onItemSelected(label);
    navigate(path); // Navigate to the item's path
  };

  console.log({ selectedItem });

  return (
    <Accordion
      open={open === id}
      icon={<Icon id={id} open={open} />}
      className={`rounded-xl bg-gradient-to-br from-white/40 to-white/10  px-2 hover:opacity-90 transition  focus:none active:none ${className}`}
    >
      <AccordionHeader
        onClick={() => handleOpen(id)}
        className={`text-white border-none hover:none focus:none active:none ${headerClassName}`}
      >
        <div className="flex items-center gap-3">
          <div
            className={`w-14 h-14 flex-shrink-0 rounded-full border flex items-center justify-center font-extrabold text-base ${
              percentage > 40 ? "text-black" : "text-white"
            }`}
            style={{
              background: `linear-gradient(0deg, #F7E758 ${percentage}%, transparent ${percentage}%)`,
            }}
          >
            <span>
              {percentage}
              <span className="text-xs">%</span>
            </span>
          </div>
          <div className="font-medium text-base text-white">{title}</div>
        </div>
      </AccordionHeader>
      <AccordionBody className={bodyClassName}>
        <div className="text-white -mt-4">
          <ul
            className={`pl-1 relative ${items.length > 1 ? "item-list-sidebar" : ""}`}
          >
            {items.map((item, index) => (
              <li
                key={index}
                className={`flex items-center gap-3 text-sm ${
                  selectedItem === item.label ? "font-bold" : "font-normal"
                } hover:bg-black/25 px-4 py-2 rounded-xl cursor-pointer transition-all`}
                onClick={() => handleItemClick(item.label, item.path, item)}
              >
                <div
                  className={`w-4 h-4 border rounded-full z-10 ${
                    selectedItem === item.label ? "bg-white" : "bg-[#68718e]"
                  }`}
                ></div>
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </AccordionBody>
    </Accordion>
  );
};

const modules = [
  {
    id: 1,
    percentage: 75,
    title: "Behavioural",
    items: [
      { label: "Cultural Fit", path: "#" },
      { label: "Problem-Solving", path: "#" },
      { label: "Leadership and Initiative", path: "#" },
      { label: "Conflict Resolution", path: "#" },
    ],
  },
  {
    id: 2,
    percentage: 10,
    title: "Technical",
    items: [
      { label: "Frontend Development", path: "/frontend-development" },
      { label: "Backend Development", path: "/backend-development" },
      { label: "DevOps", path: "/devops" },
      { label: "Database Management", path: "/database-management" },
    ],
  },
];

const Layout: React.FC<LayoutProps> = ({ secondarySidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openModule, setOpenModule] = useState(0);

  const handleOpen = (id: number) => {
    setOpenModule(openModule === id ? 0 : id);
  };

  const handleItemSelected = (item: string) => {
    console.log("Selected item:", item); // Handle item selection logic here
  };
  const { data: careerData, isLoading } = useGetUserCareer();
  // const { data:questions } = useGetChapterQuestions();

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const [open, setOpen] = React.useState(0);

  const [percentage, setPercentage] = useState(80);

  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const toggleSidebar = () => setIsSidebarHidden((prev) => !prev);

  return (
    <div className="flex h-screen relative overflow-hidden bg-[#F3F4F6] animate-blur-to-normal">
      {/* Left Sidebar */}
      <aside className="z-20 bg-[#111827] w-[120px] text-white flex-shrink-0 p-4 flex flex-col justify-between items-center py-5 px-3 relative">
        {isSidebarHidden && (
          <div
            className="w-10 h-10 flex items-center justify-center rounded-xl absolute top-4 right-[-50px] bg-black shadow-md cursor-pointer transition-all hover:bg-gray-800"
            onClick={toggleSidebar}
          >
            <ChevronDoubleRightIcon width={30} className="m-2" />
          </div>
        )}

        <div className="mb-8">
          <h1 className="text-2xl font-bold text-center">Logo</h1>
          <ul className="pt-20">
            {SIDEBAR_ITEMS.map((item) => (
              <li key={item.title} className="mb-4">
                <Link to={item.path}>
                  <Button
                    fullWidth
                    variant="text"
                    className={`flex items-center flex-col rounded-xl gap-1 hover:bg-white/10 max-w-24 py-2 ${
                      isActive(item.path)
                        ? "bg-gradient-to-br from-white/30 to-white/10"
                        : ""
                    }`}
                  >
                    <item.icon className="w-6 text-white" />
                    <span className="text-xs font-light text-white">
                      {item.title}
                    </span>
                  </Button>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <Button
            className="bg-red-500"
            onClick={() => {
              localStorage.removeItem("skillway-access-token");
              setTimeout(() => {
                navigate("/auth");
              }, 300);
            }}
          >
            <span className="text-white">Logut</span>
          </Button>
          <div>Profile</div>
        </div>
      </aside>

      <div
        className={`min-[w-360px] w-[360px] bg-[#1F2937] p-5 transition-transform duration-300 z-10 ${
          isSidebarHidden ? "transform -translate-x-full" : ""
        }`}
      >
        <div className="flex items-center justify-between text-white mb-4">
          <span className="text-xl font-medium">Topics</span>
          <HideSidebarIcon
            onClick={toggleSidebar}
            className="cursor-pointer transition-all hover:opacity-70"
          />
        </div>
        <div className="space-y-4">
          {isLoading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} height={88} mode="dark" />
            ))
          ) : careerData?.modules?.length ? (
            careerData.modules.map((module: ModuleResponse) => (
              <>
                {console.log(module)}
                <SidebarModule
                  key={module.id}
                  id={module.id}
                  open={openModule}
                  handleOpen={handleOpen}
                  percentage={module.proceed_percentage || 0}
                  title={module.title}
                  items={module.chapters.map((chapter: any) => ({
                    id: chapter.id,
                    label: chapter.title,
                    path: `/app/module/${module.id}/${chapter.id}`,
                  }))}
                  onItemSelected={handleItemSelected}
                />
              </>
            ))
          ) : (
            <div className="text-gray-500 text-center">
              No modules available.
            </div>
          )}
        </div>
      </div>

      {/* Secondary Sidebar */}
      {/* {secondarySidebar && secondarySidebar} */}

      {/* Main Content and Secondary Sidebar Wrapper */}
      <div className="flex-grow flex">
        {/* Main Content */}
        <main className="z-30 p-9 flex justify-center flex-grow">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
