import { RouteObject } from "react-router-dom";
import {
  Auth,
  AuthCallback,
  Chapter,
  Evaluation,
  Help,
  Interview,
  Module,
  ProfileCompletion,
  Roadmap,
} from "./pages";
import Resume from "pages/Resume";
import Layout from "./components/Layout";
import Admin from "pages/Admin";
import routes from "constants/routes";
import Home from "pages/Home";
import GuardedRoute from "GuardedRoute";
import NotFoundPage from "pages/NotFound";

const routesConfig: RouteObject[] = [
  {
    path: "*",
    element: <NotFoundPage />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/auth/callback",
    element: <AuthCallback />,
  },
  {
    path: "/auth",
    element: (
      <GuardedRoute
        element={<Auth />}
        authGuard={false}
        accessPermission={() => {
          const isLoggedIn = !!localStorage.getItem("skillway-access-token");
          return !isLoggedIn;
        }}
        fallbackPath="/app"
      />
    ),
  },
  {
    path: "/profile-completion",
    element: <GuardedRoute element={<ProfileCompletion />} authGuard={true} />,
  },
  {
    path: "/app",
    element: <GuardedRoute element={<Layout />} authGuard={true} />,
    children: [
      {
        path: "roadmap",
        element: <GuardedRoute element={<Roadmap />} authGuard={true} />,
      },
      {
        path: "module",
        element: <GuardedRoute element={<Module />} authGuard={true} />,
      },
      {
        path: "module/:moduleId/:chapterId",
        element: <GuardedRoute element={<Chapter />} authGuard={true} />,
      },
      {
        path: "resume",
        element: <GuardedRoute element={<Resume />} authGuard={true} />,
      },
      {
        path: "interview",
        element: <GuardedRoute element={<Interview />} authGuard={true} />,
      },
      {
        path: "help",
        element: <GuardedRoute element={<Help />} authGuard={true} />,
      },
      {
        path: "admin",
        element: (
          <GuardedRoute
            element={<Admin />}
            authGuard={true}
            accessPermission={() => {
              const userRole = localStorage.getItem("user-role");
              return userRole === "admin";
            }}
          />
        ),
      },
      {
        path: "module/:moduleId/:chapterId/:questionId/evaluation",
        element: <GuardedRoute element={<Evaluation />} authGuard={true} />,
      },
    ],
  },
];

export default routesConfig;
