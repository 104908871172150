import Button from "components/UI/Button";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const isUserLoggedIn = !!localStorage.getItem("skillway-access-token");

  return (
    <div className="flex min-h-screen items-center justify-center animate-gradient relative">
      <div className="z-10 absolute top-20 left-40 h-48 w-48 bg-gradient-to-r from-gray-700 via-gray-500 to-white blur-3xl opacity-50 animate-blur-shape"></div>
      <div className="absolute top-80 left-80 h-62 w-62 bg-gradient-to-r from-gray-700 via-gray-500 to-white blur-3xl opacity-50 animate-blur-shape"></div>
      <div className="absolute bottom-10 right-20 h-64 w-64 bg-gradient-to-r from-gray-800 via-gray-600 to-white blur-2xl opacity-30 animate-blur-shape"></div>
      <div className="absolute top-40 right-40 h-32 w-32 bg-gradient-to-r from-gray-900 via-gray-700 to-white blur-xl opacity-60 animate-blur-shape"></div>
      <div className="w-full max-w-5xl relative rounded-lg flex flex-col md:flex-row overflow-hidden z-30 animate-blur-to-normal">
        <div className="space-y-4">
          <h2 className="text-3xl">
            Welcome to <span className="font-semibold">Skillway AI</span>
          </h2>
          <div>
            {isUserLoggedIn ? (
              <Button onClick={() => navigate("/app")} colorScheme="dark">
                Go to app
              </Button>
            ) : (
              <Button onClick={() => navigate("/auth")} colorScheme="dark">
                Login
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
