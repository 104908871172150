const roadmapBase = "/app/roadmap";
const moduleBase = "/app/module";
const helpBase = "/app/help";

const routes = {
  dashboard: "/",
  roadmap: {
    BASE: roadmapBase,
  },
  module: {
    BASE: moduleBase,
    topic: `${moduleBase}/:topicId`,
  },
  help: {
    BASE: helpBase,
  },
  account: "/account",
  setting: "/setting",
  logOut: "/log-out",
};

export default routes;
