import React from "react";

type InputProps = {
  fullWidth?: boolean; // If true, input will span the full width of the parent
  placeholder?: string;
  type?: string; // Input type (e.g., text, password, email)
  className?: string; // Additional Tailwind classes for customization
  disabled?: boolean; // If true, disables the input
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      fullWidth = false,
      placeholder = "",
      type = "text",
      className = "",
      disabled = false,
      ...props // Capture all other props (e.g., value, onChange, ref, name)
    },
    ref
  ) => {
    return (
      <input
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        ref={ref} // Forward the ref to integrate with react-hook-form
        className={`border rounded-2xl h-[50px] px-4 text-sm focus:outline-none focus:ring-0 focus:ring-black ${
          fullWidth ? "w-full" : ""
        } ${
          disabled
            ? "bg-gray-100 cursor-not-allowed"
            : "bg-transparent border-gray-400 focus:border-black"
        } ${
          props["aria-invalid"] ? "border-red-500" : "border-gray-400"
        } ${className}`}
        {...props} // Spread remaining props for react-hook-form compatibility
      />
    );
  }
);

Input.displayName = "Input";

export default Input;
