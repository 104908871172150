type SkeletonProps = {
  width?: number;
  height?: number;
  className?: string;
  rounded?: "none" | "sm" | "md" | "lg" | "xl" | "full";
  mode?: "light" | "dark";
};

const Skeleton: React.FC<SkeletonProps> = ({
  height = 16,
  className = "",
  rounded = "xl",
  mode = "light",
}) => {
  const bgColor = mode === "dark" ? "bg-blue-gray-700" : "bg-gray-300";

  return (
    <div
      className={`w-full ${bgColor} animate-pulse ${className} ${
        rounded === "full" ? "rounded-full" : `rounded-${rounded}`
      }`}
      style={{ height: `${height}px` }}
    ></div>
  );
};

export default Skeleton;
