import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { Button as ButtonComponent } from "@material-tailwind/react";

type ColorScheme = "highlight" | "dark";

type ButtonProps = {
  children: React.ReactNode;
  beforeIcon?: React.ReactNode;
  afterIcon?: React.ReactNode;
  showArrowRight?: boolean;
  colorScheme?: ColorScheme;
  variant?: "filled" | "outlined" | "text";
} & React.ComponentProps<typeof ButtonComponent>;

const Button = ({
  children,
  beforeIcon,
  afterIcon,
  showArrowRight = false,
  colorScheme = "highlight", // Default color scheme
  variant = "filled", // Default variant
  ...props
}: ButtonProps) => {
  const getColorClass = (
    variant: ButtonProps["variant"],
    colorScheme: ColorScheme
  ) => {
    if (variant === "outlined" || variant === "text") {
      return "border border-current text-black";
    }

    switch (colorScheme) {
      case "highlight":
        return "bg-[#F7E758] text-black";
      case "dark":
        return "bg-black text-white";
      default:
        return "";
    }
  };

  return (
    <ButtonComponent
      className={`${getColorClass(
        variant,
        colorScheme
      )} rounded-2xl font-medium flex items-center normal-case justify-center shadow-none hover:shadow-none active:shadow-none focus:shadow-none`}
      size="lg"
      variant={variant}
      {...props}
    >
      {beforeIcon && beforeIcon}
      {children}
      {afterIcon && afterIcon}
      {showArrowRight && <ArrowRightIcon className="w-5 h-5 ml-2" />}{" "}
    </ButtonComponent>
  );
};

export default Button;
