import React from "react";

const Module: React.FC = () => {
  return (
    <>
      <div className="max-w-[600px] opacity-0 blur-md transition-all duration-700 ease-out animate-fadeIn">
        Please select one of topics
      </div>
    </>
  );
};

export default Module;
