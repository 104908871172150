import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      const error = params.get("error");

      console.log(params);

      if (token) {
        localStorage.setItem("skillway-access-token", token);
        console.log("Authentication successful:", token);
        navigate("/app");
      } else if (error) {
        // Handle the error
        console.error("Authentication error:", error);
        navigate("/auth"); // Redirect back to the login page
      }
    } catch (error) {
      console.error("Error handling authentication response:", error);
    }
  }, [navigate]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Authenticating...</h1>
    </div>
  );
};

export default AuthCallback;
