import axios from "axios";

// Create the Axios instance
const customAxiosInstance = axios.create({
  baseURL: "https://www.skillway.ai/api/",
});

// Add a request interceptor to inject the token
customAxiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("skillway-access-token");
  if (token) {
    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add a response interceptor to handle errors globally
customAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      localStorage.removeItem("skillway-access-token");
      window.location.href = "/auth";
    }
    return Promise.reject(error);
  }
);

// Generic mutator function for orval
// @ts-ignore
export const customMutator = <T>(config) => {
  return customAxiosInstance
    .request<T>(config)
    .then((response) => response.data);
};
