import { create } from "zustand";

interface AuthState {
  email: string | null; // Email entered by the user
  setEmail: (email: string) => void; // Function to set the email
  clearEmail: () => void; // Function to clear the email
}

const useAuthStore = create<AuthState>((set) => ({
  email: null,
  setEmail: (email) => set({ email }),
  clearEmail: () => set({ email: null }),
}));

export default useAuthStore;
