/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { customMutator } from "./mutator";
export type GetAllQuestionsParams = {
  id: number;
  skip?: number;
  limit?: number;
};

export type GetAllChaptersParams = {
  id: number;
  skip?: number;
  limit?: number;
};

export type GetAllModulesParams = {
  id: number;
  skip?: number;
  limit?: number;
};

export type GetAllCareersParams = {
  skip?: number;
  limit?: number;
};

export type PreLoginParams = {
  username: string;
};

export type AuthGoogleParams = {
  code: string;
};

export type UpdateUserMetadataParams = {
  /**
   * If true, sets is_new to True
   */
  reset_is_new?: boolean;
};

export type SrcUsersSchemasAnswerEvaluationResponseEvaluation = {
  [key: string]: unknown;
};

export interface SrcUsersSchemasAnswerEvaluationResponse {
  evaluation: SrcUsersSchemasAnswerEvaluationResponseEvaluation;
  question_id: number;
}

export interface SrcCareersSchemasAnswerEvaluationResponse {
  evaluation: string;
  question_id: number;
}

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

export interface UserQuestionResponse {
  goals: string[];
  id: number;
  level: string;
  question: string;
  title: string;
}

export interface UserExistenceResponse {
  new: boolean;
}

export interface UserAnswerRequest {
  answer: string;
}

export type UserAccountResponseNewToken = string | null;

export type UserAccountResponseLastName = string | null;

export type UserAccountResponseFirstName = string | null;

export interface UserAccountResponse {
  first_name: UserAccountResponseFirstName;
  last_name: UserAccountResponseLastName;
  new_token?: UserAccountResponseNewToken;
}

export interface SignupRequest {
  password: string;
  password_confirm: string;
  username: string;
}

export interface SignInRequest {
  username: string;
}

export interface ScoreHistory {
  created_at: string;
  score_explanation: string;
  score_value: number;
}

export interface QuestionDetailResponse {
  goals: string[];
  id: number;
  level: string;
  question: string;
  scores_history: ScoreHistory[];
  title: string;
}

export interface OtpVerificationRequest {
  opt_code: string;
  username: string;
}

export interface OTPVerificationResponse {
  is_new: boolean;
  token: string;
}

export type ModuleResponseChaptersItem = { [key: string]: unknown };

export interface ModuleResponse {
  chapters: ModuleResponseChaptersItem[];
  id: number;
  /**
   * @minimum 0
   * @maximum 100
   */
  proceed_percentage?: number;
  title: string;
}

export interface LoginResponse {
  access_token: string;
  token_type: string;
}

export interface LoginByPasswordRequest {
  password: string;
  username: string;
}

export type JobStatus = (typeof JobStatus)[keyof typeof JobStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobStatus = {
  Student: "Student",
  Employed: "Employed",
  Unemployed: "Unemployed",
  Looking_for_a_Job: "Looking for a Job",
  Looking_to_change_a_Job: "Looking to change a Job",
} as const;

export type Industry = (typeof Industry)[keyof typeof Industry];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Industry = {
  Frontend_Development: "Frontend Development",
  Backend_Development: "Backend Development",
  DevOps_Engineering: "DevOps Engineering",
  Quality_Assurance: "Quality Assurance",
  Agile_Delivery_Management: "Agile Delivery Management",
  Project_Management: "Project Management",
  Product_Ownership: "Product Ownership",
  Product_Design: "Product Design",
  Site_Reliability_Engineering: "Site Reliability Engineering",
} as const;

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface GetAllCareers {
  careers?: unknown[];
  total?: number;
}

export type ExperienceLevel =
  (typeof ExperienceLevel)[keyof typeof ExperienceLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExperienceLevel = {
  "Junior_(0-3_years)": "Junior (0-3 years)",
  "Mid-level_(3-7_years)": "Mid-level (3-7 years)",
  "Senior_(7+_years)": "Senior (7+ years)",
} as const;

export interface PartialUpdateUserMetaDataRequest {
  /** User's availability per week */
  available_per_week: AvailabilityPerWeek;
  /** User's country */
  country: Country;
  /** User's experience level */
  experience_level: ExperienceLevel;
  /** User's first name */
  first_name: string;
  /** User's industry */
  industry: Industry;
  /** User's current job status */
  job_status: JobStatus;
  /** User's last name */
  last_name: string;
}

export interface CreateQuestionRequest {
  chapter_id: number;
  goals: string[];
  level: string;
  question: string;
  title: string;
}

export type CreateModulesRequestDescription = string | null;

export interface CreateModulesRequest {
  career_id: number;
  description?: CreateModulesRequestDescription;
  title: string;
}

export type CreateChapterRequestDescription = string | null;

export interface CreateChapterRequest {
  analytics_prompt: string;
  description?: CreateChapterRequestDescription;
  generative_prompt: string;
  module_id: number;
  number_of_questions: number;
  title: string;
}

export interface CreateCareerRequest {
  title: string;
}

export type Country = (typeof Country)[keyof typeof Country];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Country = {
  Afghanistan: "Afghanistan",
  Albania: "Albania",
  Algeria: "Algeria",
  Andorra: "Andorra",
  Angola: "Angola",
  Antigua_and_Barbuda: "Antigua and Barbuda",
  Argentina: "Argentina",
  Armenia: "Armenia",
  Australia: "Australia",
  Austria: "Austria",
  Azerbaijan: "Azerbaijan",
  Bahamas: "Bahamas",
  Bahrain: "Bahrain",
  Bangladesh: "Bangladesh",
  Barbados: "Barbados",
  Belarus: "Belarus",
  Belgium: "Belgium",
  Belize: "Belize",
  Benin: "Benin",
  Bhutan: "Bhutan",
  Bolivia: "Bolivia",
  Bosnia_and_Herzegovina: "Bosnia and Herzegovina",
  Botswana: "Botswana",
  Brazil: "Brazil",
  Brunei: "Brunei",
  Bulgaria: "Bulgaria",
  Burkina_Faso: "Burkina Faso",
  Burundi: "Burundi",
  Cambodia: "Cambodia",
  Cameroon: "Cameroon",
  Canada: "Canada",
  Central_African_Republic: "Central African Republic",
  Chad: "Chad",
  Chile: "Chile",
  China: "China",
  Colombia: "Colombia",
  Comoros: "Comoros",
  "Congo_(Brazzaville)": "Congo (Brazzaville)",
  "Congo_(Kinshasa)": "Congo (Kinshasa)",
  Costa_Rica: "Costa Rica",
  "Côte_d'Ivoire": "Côte d'Ivoire",
  Croatia: "Croatia",
  Cuba: "Cuba",
  Cyprus: "Cyprus",
  Czech_Republic: "Czech Republic",
  Denmark: "Denmark",
  Djibouti: "Djibouti",
  Dominica: "Dominica",
  Dominican_Republic: "Dominican Republic",
  Ecuador: "Ecuador",
  Egypt: "Egypt",
  El_Salvador: "El Salvador",
  Equatorial_Guinea: "Equatorial Guinea",
  Eritrea: "Eritrea",
  Estonia: "Estonia",
  Ethiopia: "Ethiopia",
  Fiji: "Fiji",
  Finland: "Finland",
  France: "France",
  Gabon: "Gabon",
  Gambia: "Gambia",
  Georgia: "Georgia",
  Germany: "Germany",
  Ghana: "Ghana",
  Greece: "Greece",
  Greenland: "Greenland",
  Grenada: "Grenada",
  Guatemala: "Guatemala",
  Guinea: "Guinea",
  "Guinea-Bissau": "Guinea-Bissau",
  Guyana: "Guyana",
  Haiti: "Haiti",
  Honduras: "Honduras",
  Hungary: "Hungary",
  Iceland: "Iceland",
  India: "India",
  Indonesia: "Indonesia",
  Iran: "Iran",
  Iraq: "Iraq",
  Ireland: "Ireland",
  Israel: "Israel",
  Italy: "Italy",
  Ivory_Coast: "Ivory Coast",
  Jamaica: "Jamaica",
  Japan: "Japan",
  Jordan: "Jordan",
  Kazakhstan: "Kazakhstan",
  Kenya: "Kenya",
  Kiribati: "Kiribati",
  North_Korea: "North Korea",
  South_Korea: "South Korea",
  Kosovo: "Kosovo",
  Kuwait: "Kuwait",
  Kyrgyzstan: "Kyrgyzstan",
  Laos: "Laos",
  Latvia: "Latvia",
  Lebanon: "Lebanon",
  Lesotho: "Lesotho",
  Liberia: "Liberia",
  Libya: "Libya",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Lithuania",
  Luxembourg: "Luxembourg",
  "Macedonia_(FYROM)": "Macedonia (FYROM)",
  Madagascar: "Madagascar",
  Malawi: "Malawi",
  Malaysia: "Malaysia",
  Maldives: "Maldives",
  Mali: "Mali",
  Malta: "Malta",
  Marshall_Islands: "Marshall Islands",
  Mauritania: "Mauritania",
  Mauritius: "Mauritius",
  Mexico: "Mexico",
  Micronesia: "Micronesia",
  Moldova: "Moldova",
  Monaco: "Monaco",
  Mongolia: "Mongolia",
  Montenegro: "Montenegro",
  Morocco: "Morocco",
  Mozambique: "Mozambique",
  "Myanmar_(Burma)": "Myanmar (Burma)",
  Namibia: "Namibia",
  Nauru: "Nauru",
  Nepal: "Nepal",
  Netherlands: "Netherlands",
  New_Zealand: "New Zealand",
  Nicaragua: "Nicaragua",
  Niger: "Niger",
  Nigeria: "Nigeria",
  Norway: "Norway",
  Oman: "Oman",
  Pakistan: "Pakistan",
  Palau: "Palau",
  Panama: "Panama",
  Papua_New_Guinea: "Papua New Guinea",
  Paraguay: "Paraguay",
  Peru: "Peru",
  Philippines: "Philippines",
  Poland: "Poland",
  Portugal: "Portugal",
  Qatar: "Qatar",
  Romania: "Romania",
  Russia: "Russia",
  Rwanda: "Rwanda",
  Saudi_Arabia: "Saudi Arabia",
  Senegal: "Senegal",
  Serbia: "Serbia",
  Seychelles: "Seychelles",
  Sierra_Leone: "Sierra Leone",
  Singapore: "Singapore",
  "Sint_Maarten_(Dutch_part)": "Sint Maarten (Dutch part)",
  Slovakia: "Slovakia",
  Slovenia: "Slovenia",
  Solomon_Islands: "Solomon Islands",
  Somalia: "Somalia",
  South_Africa: "South Africa",
  Spain: "Spain",
  Sri_Lanka: "Sri Lanka",
  Sudan: "Sudan",
  Suriname: "Suriname",
  "Eswatini_(formerly_Swaziland)": "Eswatini (formerly Swaziland)",
  Sweden: "Sweden",
  Switzerland: "Switzerland",
  Syria: "Syria",
  Tajikistan: "Tajikistan",
  Thailand: "Thailand",
  "Timor-Leste_(East_Timor)": "Timor-Leste (East Timor)",
  Togo: "Togo",
  Tonga: "Tonga",
  Trinidad_and_Tobago: "Trinidad and Tobago",
  Tunisia: "Tunisia",
  Turkey: "Turkey",
  Turkmenistan: "Turkmenistan",
  Tuvalu: "Tuvalu",
  Uganda: "Uganda",
  Ukraine: "Ukraine",
  United_Arab_Emirates: "United Arab Emirates",
  United_Kingdom: "United Kingdom",
  United_States: "United States",
  Uruguay: "Uruguay",
  Uzbekistan: "Uzbekistan",
  Vanuatu: "Vanuatu",
  Vatican_City: "Vatican City",
  Venezuela: "Venezuela",
  Vietnam: "Vietnam",
  Yemen: "Yemen",
  Zambia: "Zambia",
  Zimbabwe: "Zimbabwe",
} as const;

export interface CareerResponse {
  id: number;
  modules: ModuleResponse[];
  title: string;
}

export type AvailabilityPerWeek =
  (typeof AvailabilityPerWeek)[keyof typeof AvailabilityPerWeek];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailabilityPerWeek = {
  Less_than_5_hours: "Less than 5 hours",
  "5-10_hours": "5-10 hours",
  "10-20_hours": "10-20 hours",
  More_than_20_hours: "More than 20 hours",
} as const;

/**
 * @summary Health
 */
export const healthHealthGet = (signal?: AbortSignal) => {
  return customMutator<unknown>({ url: `/health`, method: "GET", signal });
};

export const getHealthHealthGetQueryKey = () => {
  return [`/health`] as const;
};

export const getHealthHealthGetQueryOptions = <
  TData = Awaited<ReturnType<typeof healthHealthGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthHealthGet>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHealthHealthGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof healthHealthGet>>> = ({
    signal,
  }) => healthHealthGet(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof healthHealthGet>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type HealthHealthGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof healthHealthGet>>
>;
export type HealthHealthGetQueryError = unknown;

export function useHealthHealthGet<
  TData = Awaited<ReturnType<typeof healthHealthGet>>,
  TError = unknown,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthHealthGet>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof healthHealthGet>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useHealthHealthGet<
  TData = Awaited<ReturnType<typeof healthHealthGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthHealthGet>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof healthHealthGet>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useHealthHealthGet<
  TData = Awaited<ReturnType<typeof healthHealthGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthHealthGet>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Health
 */

export function useHealthHealthGet<
  TData = Awaited<ReturnType<typeof healthHealthGet>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthHealthGet>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getHealthHealthGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update User Metadata
 */
export const updateUserMetadata = (
  partialUpdateUserMetaDataRequest: PartialUpdateUserMetaDataRequest,
  params?: UpdateUserMetadataParams,
) => {
  return customMutator<unknown>({
    url: `/v1/users/`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: partialUpdateUserMetaDataRequest,
    params,
  });
};

export const getUpdateUserMetadataMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserMetadata>>,
    TError,
    {
      data: PartialUpdateUserMetaDataRequest;
      params?: UpdateUserMetadataParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserMetadata>>,
  TError,
  { data: PartialUpdateUserMetaDataRequest; params?: UpdateUserMetadataParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserMetadata>>,
    {
      data: PartialUpdateUserMetaDataRequest;
      params?: UpdateUserMetadataParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return updateUserMetadata(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserMetadataMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserMetadata>>
>;
export type UpdateUserMetadataMutationBody = PartialUpdateUserMetaDataRequest;
export type UpdateUserMetadataMutationError = HTTPValidationError;

/**
 * @summary Update User Metadata
 */
export const useUpdateUserMetadata = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserMetadata>>,
    TError,
    {
      data: PartialUpdateUserMetaDataRequest;
      params?: UpdateUserMetadataParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUserMetadata>>,
  TError,
  { data: PartialUpdateUserMetaDataRequest; params?: UpdateUserMetadataParams },
  TContext
> => {
  const mutationOptions = getUpdateUserMetadataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get User Career
 */
export const getUserCareer = (signal?: AbortSignal) => {
  return customMutator<CareerResponse>({
    url: `/v1/users/career`,
    method: "GET",
    signal,
  });
};

export const getGetUserCareerQueryKey = () => {
  return [`/v1/users/career`] as const;
};

export const getGetUserCareerQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserCareer>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserCareer>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserCareerQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCareer>>> = ({
    signal,
  }) => getUserCareer(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserCareer>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetUserCareerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserCareer>>
>;
export type GetUserCareerQueryError = unknown;

export function useGetUserCareer<
  TData = Awaited<ReturnType<typeof getUserCareer>>,
  TError = unknown,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserCareer>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getUserCareer>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetUserCareer<
  TData = Awaited<ReturnType<typeof getUserCareer>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserCareer>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getUserCareer>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetUserCareer<
  TData = Awaited<ReturnType<typeof getUserCareer>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserCareer>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get User Career
 */

export function useGetUserCareer<
  TData = Awaited<ReturnType<typeof getUserCareer>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserCareer>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetUserCareerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Chapter Questions
 */
export const getChapterQuestions = (
  chapterId: number,
  signal?: AbortSignal,
) => {
  return customMutator<UserQuestionResponse[]>({
    url: `/v1/users/chapters/${chapterId}/questions`,
    method: "GET",
    signal,
  });
};

export const getGetChapterQuestionsQueryKey = (chapterId: number) => {
  return [`/v1/users/chapters/${chapterId}/questions`] as const;
};

export const getGetChapterQuestionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getChapterQuestions>>,
  TError = HTTPValidationError,
>(
  chapterId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getChapterQuestions>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetChapterQuestionsQueryKey(chapterId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getChapterQuestions>>
  > = ({ signal }) => getChapterQuestions(chapterId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!chapterId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getChapterQuestions>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetChapterQuestionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getChapterQuestions>>
>;
export type GetChapterQuestionsQueryError = HTTPValidationError;

export function useGetChapterQuestions<
  TData = Awaited<ReturnType<typeof getChapterQuestions>>,
  TError = HTTPValidationError,
>(
  chapterId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getChapterQuestions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getChapterQuestions>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetChapterQuestions<
  TData = Awaited<ReturnType<typeof getChapterQuestions>>,
  TError = HTTPValidationError,
>(
  chapterId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getChapterQuestions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getChapterQuestions>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetChapterQuestions<
  TData = Awaited<ReturnType<typeof getChapterQuestions>>,
  TError = HTTPValidationError,
>(
  chapterId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getChapterQuestions>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Chapter Questions
 */

export function useGetChapterQuestions<
  TData = Awaited<ReturnType<typeof getChapterQuestions>>,
  TError = HTTPValidationError,
>(
  chapterId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getChapterQuestions>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetChapterQuestionsQueryOptions(chapterId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Evaluate User Answer
 */
export const evaluateUserAnswer = (
  questionId: number,
  userAnswerRequest: UserAnswerRequest,
  signal?: AbortSignal,
) => {
  return customMutator<SrcCareersSchemasAnswerEvaluationResponse>({
    url: `/v1/questions/${questionId}/evaluate`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: userAnswerRequest,
    signal,
  });
};

export const getEvaluateUserAnswerMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof evaluateUserAnswer>>,
    TError,
    { questionId: number; data: UserAnswerRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof evaluateUserAnswer>>,
  TError,
  { questionId: number; data: UserAnswerRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof evaluateUserAnswer>>,
    { questionId: number; data: UserAnswerRequest }
  > = (props) => {
    const { questionId, data } = props ?? {};

    return evaluateUserAnswer(questionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type EvaluateUserAnswerMutationResult = NonNullable<
  Awaited<ReturnType<typeof evaluateUserAnswer>>
>;
export type EvaluateUserAnswerMutationBody = UserAnswerRequest;
export type EvaluateUserAnswerMutationError = HTTPValidationError;

/**
 * @summary Evaluate User Answer
 */
export const useEvaluateUserAnswer = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof evaluateUserAnswer>>,
    TError,
    { questionId: number; data: UserAnswerRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof evaluateUserAnswer>>,
  TError,
  { questionId: number; data: UserAnswerRequest },
  TContext
> => {
  const mutationOptions = getEvaluateUserAnswerMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get User Account
 */
export const getUserAccount = (signal?: AbortSignal) => {
  return customMutator<UserAccountResponse>({
    url: `/v1/users/account`,
    method: "GET",
    signal,
  });
};

export const getGetUserAccountQueryKey = () => {
  return [`/v1/users/account`] as const;
};

export const getGetUserAccountQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserAccount>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserAccountQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAccount>>> = ({
    signal,
  }) => getUserAccount(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserAccount>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetUserAccountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserAccount>>
>;
export type GetUserAccountQueryError = unknown;

export function useGetUserAccount<
  TData = Awaited<ReturnType<typeof getUserAccount>>,
  TError = unknown,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserAccount>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getUserAccount>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetUserAccount<
  TData = Awaited<ReturnType<typeof getUserAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserAccount>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getUserAccount>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetUserAccount<
  TData = Awaited<ReturnType<typeof getUserAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserAccount>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get User Account
 */

export function useGetUserAccount<
  TData = Awaited<ReturnType<typeof getUserAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserAccount>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetUserAccountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Question Detail
 */
export const getQuestionDetail = (questionId: number, signal?: AbortSignal) => {
  return customMutator<QuestionDetailResponse>({
    url: `/v1/users/questions/${questionId}/detail`,
    method: "GET",
    signal,
  });
};

export const getGetQuestionDetailQueryKey = (questionId: number) => {
  return [`/v1/users/questions/${questionId}/detail`] as const;
};

export const getGetQuestionDetailQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuestionDetail>>,
  TError = HTTPValidationError,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuestionDetail>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetQuestionDetailQueryKey(questionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getQuestionDetail>>
  > = ({ signal }) => getQuestionDetail(questionId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!questionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuestionDetail>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetQuestionDetailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuestionDetail>>
>;
export type GetQuestionDetailQueryError = HTTPValidationError;

export function useGetQuestionDetail<
  TData = Awaited<ReturnType<typeof getQuestionDetail>>,
  TError = HTTPValidationError,
>(
  questionId: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuestionDetail>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuestionDetail>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetQuestionDetail<
  TData = Awaited<ReturnType<typeof getQuestionDetail>>,
  TError = HTTPValidationError,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuestionDetail>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuestionDetail>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetQuestionDetail<
  TData = Awaited<ReturnType<typeof getQuestionDetail>>,
  TError = HTTPValidationError,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuestionDetail>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Question Detail
 */

export function useGetQuestionDetail<
  TData = Awaited<ReturnType<typeof getQuestionDetail>>,
  TError = HTTPValidationError,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getQuestionDetail>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetQuestionDetailQueryOptions(questionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Login Google
 */
export const loginGoogle = (signal?: AbortSignal) => {
  return customMutator<unknown>({
    url: `/v1/auth/login/google`,
    method: "GET",
    signal,
  });
};

export const getLoginGoogleQueryKey = () => {
  return [`/v1/auth/login/google`] as const;
};

export const getLoginGoogleQueryOptions = <
  TData = Awaited<ReturnType<typeof loginGoogle>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof loginGoogle>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLoginGoogleQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof loginGoogle>>> = ({
    signal,
  }) => loginGoogle(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof loginGoogle>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type LoginGoogleQueryResult = NonNullable<
  Awaited<ReturnType<typeof loginGoogle>>
>;
export type LoginGoogleQueryError = unknown;

export function useLoginGoogle<
  TData = Awaited<ReturnType<typeof loginGoogle>>,
  TError = unknown,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof loginGoogle>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof loginGoogle>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useLoginGoogle<
  TData = Awaited<ReturnType<typeof loginGoogle>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof loginGoogle>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof loginGoogle>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useLoginGoogle<
  TData = Awaited<ReturnType<typeof loginGoogle>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof loginGoogle>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Login Google
 */

export function useLoginGoogle<
  TData = Awaited<ReturnType<typeof loginGoogle>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof loginGoogle>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getLoginGoogleQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Auth Google
 */
export const authGoogle = (params: AuthGoogleParams, signal?: AbortSignal) => {
  return customMutator<unknown>({
    url: `/v1/auth/google`,
    method: "GET",
    params,
    signal,
  });
};

export const getAuthGoogleQueryKey = (params: AuthGoogleParams) => {
  return [`/v1/auth/google`, ...(params ? [params] : [])] as const;
};

export const getAuthGoogleQueryOptions = <
  TData = Awaited<ReturnType<typeof authGoogle>>,
  TError = HTTPValidationError,
>(
  params: AuthGoogleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof authGoogle>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthGoogleQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof authGoogle>>> = ({
    signal,
  }) => authGoogle(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authGoogle>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AuthGoogleQueryResult = NonNullable<
  Awaited<ReturnType<typeof authGoogle>>
>;
export type AuthGoogleQueryError = HTTPValidationError;

export function useAuthGoogle<
  TData = Awaited<ReturnType<typeof authGoogle>>,
  TError = HTTPValidationError,
>(
  params: AuthGoogleParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof authGoogle>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authGoogle>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAuthGoogle<
  TData = Awaited<ReturnType<typeof authGoogle>>,
  TError = HTTPValidationError,
>(
  params: AuthGoogleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof authGoogle>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authGoogle>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAuthGoogle<
  TData = Awaited<ReturnType<typeof authGoogle>>,
  TError = HTTPValidationError,
>(
  params: AuthGoogleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof authGoogle>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Auth Google
 */

export function useAuthGoogle<
  TData = Awaited<ReturnType<typeof authGoogle>>,
  TError = HTTPValidationError,
>(
  params: AuthGoogleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof authGoogle>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAuthGoogleQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Sign In
 */
export const signIn = (signInRequest: SignInRequest, signal?: AbortSignal) => {
  return customMutator<unknown>({
    url: `/v1/auth/sign-in`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: signInRequest,
    signal,
  });
};

export const getSignInMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signIn>>,
    TError,
    { data: SignInRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof signIn>>,
  TError,
  { data: SignInRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof signIn>>,
    { data: SignInRequest }
  > = (props) => {
    const { data } = props ?? {};

    return signIn(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignInMutationResult = NonNullable<
  Awaited<ReturnType<typeof signIn>>
>;
export type SignInMutationBody = SignInRequest;
export type SignInMutationError = HTTPValidationError;

/**
 * @summary Sign In
 */
export const useSignIn = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signIn>>,
    TError,
    { data: SignInRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof signIn>>,
  TError,
  { data: SignInRequest },
  TContext
> => {
  const mutationOptions = getSignInMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Otp Verification
 */
export const otpVerification = (
  otpVerificationRequest: OtpVerificationRequest,
  signal?: AbortSignal,
) => {
  return customMutator<OTPVerificationResponse>({
    url: `/v1/auth/otp-verification`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: otpVerificationRequest,
    signal,
  });
};

export const getOtpVerificationMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof otpVerification>>,
    TError,
    { data: OtpVerificationRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof otpVerification>>,
  TError,
  { data: OtpVerificationRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof otpVerification>>,
    { data: OtpVerificationRequest }
  > = (props) => {
    const { data } = props ?? {};

    return otpVerification(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type OtpVerificationMutationResult = NonNullable<
  Awaited<ReturnType<typeof otpVerification>>
>;
export type OtpVerificationMutationBody = OtpVerificationRequest;
export type OtpVerificationMutationError = HTTPValidationError;

/**
 * @summary Otp Verification
 */
export const useOtpVerification = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof otpVerification>>,
    TError,
    { data: OtpVerificationRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof otpVerification>>,
  TError,
  { data: OtpVerificationRequest },
  TContext
> => {
  const mutationOptions = getOtpVerificationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Login
 */
export const login = (
  loginByPasswordRequest: LoginByPasswordRequest,
  signal?: AbortSignal,
) => {
  return customMutator<LoginResponse>({
    url: `/v1/auth/login`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: loginByPasswordRequest,
    signal,
  });
};

export const getLoginMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginByPasswordRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof login>>,
  TError,
  { data: LoginByPasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof login>>,
    { data: LoginByPasswordRequest }
  > = (props) => {
    const { data } = props ?? {};

    return login(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof login>>
>;
export type LoginMutationBody = LoginByPasswordRequest;
export type LoginMutationError = HTTPValidationError;

/**
 * @summary Login
 */
export const useLogin = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginByPasswordRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof login>>,
  TError,
  { data: LoginByPasswordRequest },
  TContext
> => {
  const mutationOptions = getLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Signup
 */
export const signup = (signupRequest: SignupRequest, signal?: AbortSignal) => {
  return customMutator<unknown>({
    url: `/v1/auth/signup`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: signupRequest,
    signal,
  });
};

export const getSignupMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signup>>,
    TError,
    { data: SignupRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof signup>>,
  TError,
  { data: SignupRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof signup>>,
    { data: SignupRequest }
  > = (props) => {
    const { data } = props ?? {};

    return signup(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignupMutationResult = NonNullable<
  Awaited<ReturnType<typeof signup>>
>;
export type SignupMutationBody = SignupRequest;
export type SignupMutationError = HTTPValidationError;

/**
 * @summary Signup
 */
export const useSignup = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signup>>,
    TError,
    { data: SignupRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof signup>>,
  TError,
  { data: SignupRequest },
  TContext
> => {
  const mutationOptions = getSignupMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Check User Existence
 */
export const preLogin = (params: PreLoginParams, signal?: AbortSignal) => {
  return customMutator<UserExistenceResponse>({
    url: `/v1/auth/pre-login`,
    method: "POST",
    params,
    signal,
  });
};

export const getPreLoginMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof preLogin>>,
    TError,
    { params: PreLoginParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof preLogin>>,
  TError,
  { params: PreLoginParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof preLogin>>,
    { params: PreLoginParams }
  > = (props) => {
    const { params } = props ?? {};

    return preLogin(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type PreLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof preLogin>>
>;

export type PreLoginMutationError = HTTPValidationError;

/**
 * @summary Check User Existence
 */
export const usePreLogin = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof preLogin>>,
    TError,
    { params: PreLoginParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof preLogin>>,
  TError,
  { params: PreLoginParams },
  TContext
> => {
  const mutationOptions = getPreLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create Career
 */
export const createCareer = (
  createCareerRequest: CreateCareerRequest,
  signal?: AbortSignal,
) => {
  return customMutator<unknown>({
    url: `/v1/careers/`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createCareerRequest,
    signal,
  });
};

export const getCreateCareerMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCareer>>,
    TError,
    { data: CreateCareerRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCareer>>,
  TError,
  { data: CreateCareerRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCareer>>,
    { data: CreateCareerRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createCareer(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCareerMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCareer>>
>;
export type CreateCareerMutationBody = CreateCareerRequest;
export type CreateCareerMutationError = HTTPValidationError;

/**
 * @summary Create Career
 */
export const useCreateCareer = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCareer>>,
    TError,
    { data: CreateCareerRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCareer>>,
  TError,
  { data: CreateCareerRequest },
  TContext
> => {
  const mutationOptions = getCreateCareerMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get All Careers
 */
export const getAllCareers = (
  params?: GetAllCareersParams,
  signal?: AbortSignal,
) => {
  return customMutator<GetAllCareers>({
    url: `/v1/careers/`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetAllCareersQueryKey = (params?: GetAllCareersParams) => {
  return [`/v1/careers/`, ...(params ? [params] : [])] as const;
};

export const getGetAllCareersQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllCareers>>,
  TError = HTTPValidationError,
>(
  params?: GetAllCareersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllCareers>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllCareersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllCareers>>> = ({
    signal,
  }) => getAllCareers(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllCareers>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAllCareersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllCareers>>
>;
export type GetAllCareersQueryError = HTTPValidationError;

export function useGetAllCareers<
  TData = Awaited<ReturnType<typeof getAllCareers>>,
  TError = HTTPValidationError,
>(
  params: undefined | GetAllCareersParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllCareers>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllCareers>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetAllCareers<
  TData = Awaited<ReturnType<typeof getAllCareers>>,
  TError = HTTPValidationError,
>(
  params?: GetAllCareersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllCareers>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllCareers>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAllCareers<
  TData = Awaited<ReturnType<typeof getAllCareers>>,
  TError = HTTPValidationError,
>(
  params?: GetAllCareersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllCareers>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get All Careers
 */

export function useGetAllCareers<
  TData = Awaited<ReturnType<typeof getAllCareers>>,
  TError = HTTPValidationError,
>(
  params?: GetAllCareersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllCareers>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAllCareersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Career
 */
export const getCareer = (careerId: number, signal?: AbortSignal) => {
  return customMutator<unknown>({
    url: `/v1/careers/${careerId}`,
    method: "GET",
    signal,
  });
};

export const getGetCareerQueryKey = (careerId: number) => {
  return [`/v1/careers/${careerId}`] as const;
};

export const getGetCareerQueryOptions = <
  TData = Awaited<ReturnType<typeof getCareer>>,
  TError = HTTPValidationError,
>(
  careerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCareer>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCareerQueryKey(careerId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCareer>>> = ({
    signal,
  }) => getCareer(careerId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!careerId,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getCareer>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type GetCareerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCareer>>
>;
export type GetCareerQueryError = HTTPValidationError;

export function useGetCareer<
  TData = Awaited<ReturnType<typeof getCareer>>,
  TError = HTTPValidationError,
>(
  careerId: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCareer>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCareer>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetCareer<
  TData = Awaited<ReturnType<typeof getCareer>>,
  TError = HTTPValidationError,
>(
  careerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCareer>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCareer>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetCareer<
  TData = Awaited<ReturnType<typeof getCareer>>,
  TError = HTTPValidationError,
>(
  careerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCareer>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Career
 */

export function useGetCareer<
  TData = Awaited<ReturnType<typeof getCareer>>,
  TError = HTTPValidationError,
>(
  careerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCareer>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetCareerQueryOptions(careerId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Career
 */
export const updateCareer = (
  careerId: number,
  createCareerRequest: CreateCareerRequest,
) => {
  return customMutator<unknown>({
    url: `/v1/careers/${careerId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: createCareerRequest,
  });
};

export const getUpdateCareerMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCareer>>,
    TError,
    { careerId: number; data: CreateCareerRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCareer>>,
  TError,
  { careerId: number; data: CreateCareerRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCareer>>,
    { careerId: number; data: CreateCareerRequest }
  > = (props) => {
    const { careerId, data } = props ?? {};

    return updateCareer(careerId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCareerMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCareer>>
>;
export type UpdateCareerMutationBody = CreateCareerRequest;
export type UpdateCareerMutationError = HTTPValidationError;

/**
 * @summary Update Career
 */
export const useUpdateCareer = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCareer>>,
    TError,
    { careerId: number; data: CreateCareerRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCareer>>,
  TError,
  { careerId: number; data: CreateCareerRequest },
  TContext
> => {
  const mutationOptions = getUpdateCareerMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete Career
 */
export const deleteCareer = (careerId: number) => {
  return customMutator<unknown>({
    url: `/v1/careers/${careerId}`,
    method: "DELETE",
  });
};

export const getDeleteCareerMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCareer>>,
    TError,
    { careerId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCareer>>,
  TError,
  { careerId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCareer>>,
    { careerId: number }
  > = (props) => {
    const { careerId } = props ?? {};

    return deleteCareer(careerId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCareerMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCareer>>
>;

export type DeleteCareerMutationError = HTTPValidationError;

/**
 * @summary Delete Career
 */
export const useDeleteCareer = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCareer>>,
    TError,
    { careerId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCareer>>,
  TError,
  { careerId: number },
  TContext
> => {
  const mutationOptions = getDeleteCareerMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create Module
 */
export const createModule = (
  createModulesRequest: CreateModulesRequest,
  signal?: AbortSignal,
) => {
  return customMutator<unknown>({
    url: `/v1/modules/`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createModulesRequest,
    signal,
  });
};

export const getCreateModuleMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createModule>>,
    TError,
    { data: CreateModulesRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createModule>>,
  TError,
  { data: CreateModulesRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createModule>>,
    { data: CreateModulesRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createModule(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateModuleMutationResult = NonNullable<
  Awaited<ReturnType<typeof createModule>>
>;
export type CreateModuleMutationBody = CreateModulesRequest;
export type CreateModuleMutationError = HTTPValidationError;

/**
 * @summary Create Module
 */
export const useCreateModule = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createModule>>,
    TError,
    { data: CreateModulesRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createModule>>,
  TError,
  { data: CreateModulesRequest },
  TContext
> => {
  const mutationOptions = getCreateModuleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get All Modules
 */
export const getAllModules = (
  params: GetAllModulesParams,
  signal?: AbortSignal,
) => {
  return customMutator<unknown>({
    url: `/v1/modules/`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetAllModulesQueryKey = (params: GetAllModulesParams) => {
  return [`/v1/modules/`, ...(params ? [params] : [])] as const;
};

export const getGetAllModulesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllModules>>,
  TError = HTTPValidationError,
>(
  params: GetAllModulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllModules>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllModulesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllModules>>> = ({
    signal,
  }) => getAllModules(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllModules>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAllModulesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllModules>>
>;
export type GetAllModulesQueryError = HTTPValidationError;

export function useGetAllModules<
  TData = Awaited<ReturnType<typeof getAllModules>>,
  TError = HTTPValidationError,
>(
  params: GetAllModulesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllModules>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllModules>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetAllModules<
  TData = Awaited<ReturnType<typeof getAllModules>>,
  TError = HTTPValidationError,
>(
  params: GetAllModulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllModules>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllModules>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAllModules<
  TData = Awaited<ReturnType<typeof getAllModules>>,
  TError = HTTPValidationError,
>(
  params: GetAllModulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllModules>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get All Modules
 */

export function useGetAllModules<
  TData = Awaited<ReturnType<typeof getAllModules>>,
  TError = HTTPValidationError,
>(
  params: GetAllModulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllModules>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAllModulesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Module
 */
export const updateModule = (
  moduleId: number,
  createModulesRequest: CreateModulesRequest,
) => {
  return customMutator<unknown>({
    url: `/v1/modules/${moduleId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: createModulesRequest,
  });
};

export const getUpdateModuleMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateModule>>,
    TError,
    { moduleId: number; data: CreateModulesRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateModule>>,
  TError,
  { moduleId: number; data: CreateModulesRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateModule>>,
    { moduleId: number; data: CreateModulesRequest }
  > = (props) => {
    const { moduleId, data } = props ?? {};

    return updateModule(moduleId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateModuleMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateModule>>
>;
export type UpdateModuleMutationBody = CreateModulesRequest;
export type UpdateModuleMutationError = HTTPValidationError;

/**
 * @summary Update Module
 */
export const useUpdateModule = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateModule>>,
    TError,
    { moduleId: number; data: CreateModulesRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateModule>>,
  TError,
  { moduleId: number; data: CreateModulesRequest },
  TContext
> => {
  const mutationOptions = getUpdateModuleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Module
 */
export const getModule = (moduleId: number, signal?: AbortSignal) => {
  return customMutator<unknown>({
    url: `/v1/modules/${moduleId}`,
    method: "GET",
    signal,
  });
};

export const getGetModuleQueryKey = (moduleId: number) => {
  return [`/v1/modules/${moduleId}`] as const;
};

export const getGetModuleQueryOptions = <
  TData = Awaited<ReturnType<typeof getModule>>,
  TError = HTTPValidationError,
>(
  moduleId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getModule>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModuleQueryKey(moduleId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getModule>>> = ({
    signal,
  }) => getModule(moduleId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!moduleId,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getModule>>, TError, TData> & {
    queryKey: DataTag<QueryKey, TData>;
  };
};

export type GetModuleQueryResult = NonNullable<
  Awaited<ReturnType<typeof getModule>>
>;
export type GetModuleQueryError = HTTPValidationError;

export function useGetModule<
  TData = Awaited<ReturnType<typeof getModule>>,
  TError = HTTPValidationError,
>(
  moduleId: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getModule>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getModule>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetModule<
  TData = Awaited<ReturnType<typeof getModule>>,
  TError = HTTPValidationError,
>(
  moduleId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getModule>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getModule>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetModule<
  TData = Awaited<ReturnType<typeof getModule>>,
  TError = HTTPValidationError,
>(
  moduleId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getModule>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Module
 */

export function useGetModule<
  TData = Awaited<ReturnType<typeof getModule>>,
  TError = HTTPValidationError,
>(
  moduleId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getModule>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetModuleQueryOptions(moduleId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Delete Module
 */
export const deleteModule = (moduleId: number) => {
  return customMutator<unknown>({
    url: `/v1/modules/${moduleId}`,
    method: "DELETE",
  });
};

export const getDeleteModuleMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteModule>>,
    TError,
    { moduleId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteModule>>,
  TError,
  { moduleId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteModule>>,
    { moduleId: number }
  > = (props) => {
    const { moduleId } = props ?? {};

    return deleteModule(moduleId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteModuleMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteModule>>
>;

export type DeleteModuleMutationError = HTTPValidationError;

/**
 * @summary Delete Module
 */
export const useDeleteModule = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteModule>>,
    TError,
    { moduleId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteModule>>,
  TError,
  { moduleId: number },
  TContext
> => {
  const mutationOptions = getDeleteModuleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create Chapter
 */
export const createChapter = (
  createChapterRequest: CreateChapterRequest,
  signal?: AbortSignal,
) => {
  return customMutator<unknown>({
    url: `/v1/chapters/`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createChapterRequest,
    signal,
  });
};

export const getCreateChapterMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createChapter>>,
    TError,
    { data: CreateChapterRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createChapter>>,
  TError,
  { data: CreateChapterRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createChapter>>,
    { data: CreateChapterRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createChapter(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateChapterMutationResult = NonNullable<
  Awaited<ReturnType<typeof createChapter>>
>;
export type CreateChapterMutationBody = CreateChapterRequest;
export type CreateChapterMutationError = HTTPValidationError;

/**
 * @summary Create Chapter
 */
export const useCreateChapter = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createChapter>>,
    TError,
    { data: CreateChapterRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createChapter>>,
  TError,
  { data: CreateChapterRequest },
  TContext
> => {
  const mutationOptions = getCreateChapterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get All Chapters
 */
export const getAllChapters = (
  params: GetAllChaptersParams,
  signal?: AbortSignal,
) => {
  return customMutator<unknown>({
    url: `/v1/chapters/`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetAllChaptersQueryKey = (params: GetAllChaptersParams) => {
  return [`/v1/chapters/`, ...(params ? [params] : [])] as const;
};

export const getGetAllChaptersQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllChapters>>,
  TError = HTTPValidationError,
>(
  params: GetAllChaptersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllChapters>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllChaptersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllChapters>>> = ({
    signal,
  }) => getAllChapters(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllChapters>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAllChaptersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllChapters>>
>;
export type GetAllChaptersQueryError = HTTPValidationError;

export function useGetAllChapters<
  TData = Awaited<ReturnType<typeof getAllChapters>>,
  TError = HTTPValidationError,
>(
  params: GetAllChaptersParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllChapters>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllChapters>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetAllChapters<
  TData = Awaited<ReturnType<typeof getAllChapters>>,
  TError = HTTPValidationError,
>(
  params: GetAllChaptersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllChapters>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllChapters>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAllChapters<
  TData = Awaited<ReturnType<typeof getAllChapters>>,
  TError = HTTPValidationError,
>(
  params: GetAllChaptersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllChapters>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get All Chapters
 */

export function useGetAllChapters<
  TData = Awaited<ReturnType<typeof getAllChapters>>,
  TError = HTTPValidationError,
>(
  params: GetAllChaptersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAllChapters>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAllChaptersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Chapter
 */
export const updateChapter = (
  chapterId: number,
  createChapterRequest: CreateChapterRequest,
) => {
  return customMutator<unknown>({
    url: `/v1/chapters/${chapterId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: createChapterRequest,
  });
};

export const getUpdateChapterMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateChapter>>,
    TError,
    { chapterId: number; data: CreateChapterRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateChapter>>,
  TError,
  { chapterId: number; data: CreateChapterRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateChapter>>,
    { chapterId: number; data: CreateChapterRequest }
  > = (props) => {
    const { chapterId, data } = props ?? {};

    return updateChapter(chapterId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateChapterMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateChapter>>
>;
export type UpdateChapterMutationBody = CreateChapterRequest;
export type UpdateChapterMutationError = HTTPValidationError;

/**
 * @summary Update Chapter
 */
export const useUpdateChapter = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateChapter>>,
    TError,
    { chapterId: number; data: CreateChapterRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateChapter>>,
  TError,
  { chapterId: number; data: CreateChapterRequest },
  TContext
> => {
  const mutationOptions = getUpdateChapterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Chapter
 */
export const getChapter = (chapterId: number, signal?: AbortSignal) => {
  return customMutator<unknown>({
    url: `/v1/chapters/${chapterId}`,
    method: "GET",
    signal,
  });
};

export const getGetChapterQueryKey = (chapterId: number) => {
  return [`/v1/chapters/${chapterId}`] as const;
};

export const getGetChapterQueryOptions = <
  TData = Awaited<ReturnType<typeof getChapter>>,
  TError = HTTPValidationError,
>(
  chapterId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getChapter>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetChapterQueryKey(chapterId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChapter>>> = ({
    signal,
  }) => getChapter(chapterId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!chapterId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getChapter>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetChapterQueryResult = NonNullable<
  Awaited<ReturnType<typeof getChapter>>
>;
export type GetChapterQueryError = HTTPValidationError;

export function useGetChapter<
  TData = Awaited<ReturnType<typeof getChapter>>,
  TError = HTTPValidationError,
>(
  chapterId: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getChapter>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getChapter>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetChapter<
  TData = Awaited<ReturnType<typeof getChapter>>,
  TError = HTTPValidationError,
>(
  chapterId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getChapter>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getChapter>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetChapter<
  TData = Awaited<ReturnType<typeof getChapter>>,
  TError = HTTPValidationError,
>(
  chapterId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getChapter>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Chapter
 */

export function useGetChapter<
  TData = Awaited<ReturnType<typeof getChapter>>,
  TError = HTTPValidationError,
>(
  chapterId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getChapter>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetChapterQueryOptions(chapterId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Delete Chapter
 */
export const deleteChapter = (chapterId: number) => {
  return customMutator<unknown>({
    url: `/v1/chapters/${chapterId}`,
    method: "DELETE",
  });
};

export const getDeleteChapterMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteChapter>>,
    TError,
    { chapterId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteChapter>>,
  TError,
  { chapterId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteChapter>>,
    { chapterId: number }
  > = (props) => {
    const { chapterId } = props ?? {};

    return deleteChapter(chapterId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteChapterMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteChapter>>
>;

export type DeleteChapterMutationError = HTTPValidationError;

/**
 * @summary Delete Chapter
 */
export const useDeleteChapter = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteChapter>>,
    TError,
    { chapterId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteChapter>>,
  TError,
  { chapterId: number },
  TContext
> => {
  const mutationOptions = getDeleteChapterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Generate Chapter Questions
 */
export const generateChapterQuestions = (
  chapterId: number,
  signal?: AbortSignal,
) => {
  return customMutator<unknown>({
    url: `/v1/chapters/${chapterId}/questions`,
    method: "POST",
    signal,
  });
};

export const getGenerateChapterQuestionsMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateChapterQuestions>>,
    TError,
    { chapterId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateChapterQuestions>>,
  TError,
  { chapterId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateChapterQuestions>>,
    { chapterId: number }
  > = (props) => {
    const { chapterId } = props ?? {};

    return generateChapterQuestions(chapterId);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateChapterQuestionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateChapterQuestions>>
>;

export type GenerateChapterQuestionsMutationError = HTTPValidationError;

/**
 * @summary Generate Chapter Questions
 */
export const useGenerateChapterQuestions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateChapterQuestions>>,
    TError,
    { chapterId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateChapterQuestions>>,
  TError,
  { chapterId: number },
  TContext
> => {
  const mutationOptions = getGenerateChapterQuestionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create Question
 */
export const createQuestion = (
  createQuestionRequest: CreateQuestionRequest,
  signal?: AbortSignal,
) => {
  return customMutator<unknown>({
    url: `/v1/questions/`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createQuestionRequest,
    signal,
  });
};

export const getCreateQuestionMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuestion>>,
    TError,
    { data: CreateQuestionRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createQuestion>>,
  TError,
  { data: CreateQuestionRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createQuestion>>,
    { data: CreateQuestionRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createQuestion(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateQuestionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createQuestion>>
>;
export type CreateQuestionMutationBody = CreateQuestionRequest;
export type CreateQuestionMutationError = HTTPValidationError;

/**
 * @summary Create Question
 */
export const useCreateQuestion = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createQuestion>>,
    TError,
    { data: CreateQuestionRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createQuestion>>,
  TError,
  { data: CreateQuestionRequest },
  TContext
> => {
  const mutationOptions = getCreateQuestionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get All Questions
 */
export const getAllQuestions = (
  params: GetAllQuestionsParams,
  signal?: AbortSignal,
) => {
  return customMutator<unknown>({
    url: `/v1/questions/`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetAllQuestionsQueryKey = (params: GetAllQuestionsParams) => {
  return [`/v1/questions/`, ...(params ? [params] : [])] as const;
};

export const getGetAllQuestionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllQuestions>>,
  TError = HTTPValidationError,
>(
  params: GetAllQuestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllQuestions>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllQuestionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllQuestions>>> = ({
    signal,
  }) => getAllQuestions(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllQuestions>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAllQuestionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllQuestions>>
>;
export type GetAllQuestionsQueryError = HTTPValidationError;

export function useGetAllQuestions<
  TData = Awaited<ReturnType<typeof getAllQuestions>>,
  TError = HTTPValidationError,
>(
  params: GetAllQuestionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllQuestions>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllQuestions>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetAllQuestions<
  TData = Awaited<ReturnType<typeof getAllQuestions>>,
  TError = HTTPValidationError,
>(
  params: GetAllQuestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllQuestions>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllQuestions>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAllQuestions<
  TData = Awaited<ReturnType<typeof getAllQuestions>>,
  TError = HTTPValidationError,
>(
  params: GetAllQuestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllQuestions>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get All Questions
 */

export function useGetAllQuestions<
  TData = Awaited<ReturnType<typeof getAllQuestions>>,
  TError = HTTPValidationError,
>(
  params: GetAllQuestionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAllQuestions>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAllQuestionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Question
 */
export const updateQuestion = (
  questionId: number,
  createQuestionRequest: CreateQuestionRequest,
) => {
  return customMutator<unknown>({
    url: `/v1/questions/${questionId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: createQuestionRequest,
  });
};

export const getUpdateQuestionMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateQuestion>>,
    TError,
    { questionId: number; data: CreateQuestionRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateQuestion>>,
  TError,
  { questionId: number; data: CreateQuestionRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateQuestion>>,
    { questionId: number; data: CreateQuestionRequest }
  > = (props) => {
    const { questionId, data } = props ?? {};

    return updateQuestion(questionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateQuestionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateQuestion>>
>;
export type UpdateQuestionMutationBody = CreateQuestionRequest;
export type UpdateQuestionMutationError = HTTPValidationError;

/**
 * @summary Update Question
 */
export const useUpdateQuestion = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateQuestion>>,
    TError,
    { questionId: number; data: CreateQuestionRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateQuestion>>,
  TError,
  { questionId: number; data: CreateQuestionRequest },
  TContext
> => {
  const mutationOptions = getUpdateQuestionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Question
 */
export const getQuestion = (questionId: number, signal?: AbortSignal) => {
  return customMutator<unknown>({
    url: `/v1/questions/${questionId}`,
    method: "GET",
    signal,
  });
};

export const getGetQuestionQueryKey = (questionId: number) => {
  return [`/v1/questions/${questionId}`] as const;
};

export const getGetQuestionQueryOptions = <
  TData = Awaited<ReturnType<typeof getQuestion>>,
  TError = HTTPValidationError,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getQuestion>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuestionQueryKey(questionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuestion>>> = ({
    signal,
  }) => getQuestion(questionId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!questionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getQuestion>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetQuestionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuestion>>
>;
export type GetQuestionQueryError = HTTPValidationError;

export function useGetQuestion<
  TData = Awaited<ReturnType<typeof getQuestion>>,
  TError = HTTPValidationError,
>(
  questionId: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getQuestion>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuestion>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetQuestion<
  TData = Awaited<ReturnType<typeof getQuestion>>,
  TError = HTTPValidationError,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getQuestion>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getQuestion>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetQuestion<
  TData = Awaited<ReturnType<typeof getQuestion>>,
  TError = HTTPValidationError,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getQuestion>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Question
 */

export function useGetQuestion<
  TData = Awaited<ReturnType<typeof getQuestion>>,
  TError = HTTPValidationError,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getQuestion>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetQuestionQueryOptions(questionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Delete Question
 */
export const deleteQuestion = (questionId: number) => {
  return customMutator<unknown>({
    url: `/v1/questions/${questionId}`,
    method: "DELETE",
  });
};

export const getDeleteQuestionMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuestion>>,
    TError,
    { questionId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteQuestion>>,
  TError,
  { questionId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteQuestion>>,
    { questionId: number }
  > = (props) => {
    const { questionId } = props ?? {};

    return deleteQuestion(questionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteQuestionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteQuestion>>
>;

export type DeleteQuestionMutationError = HTTPValidationError;

/**
 * @summary Delete Question
 */
export const useDeleteQuestion = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteQuestion>>,
    TError,
    { questionId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteQuestion>>,
  TError,
  { questionId: number },
  TContext
> => {
  const mutationOptions = getDeleteQuestionMutationOptions(options);

  return useMutation(mutationOptions);
};
