import React from "react";
import { Navigate } from "react-router-dom";

interface GuardedRouteProps {
  element: JSX.Element;
  authGuard?: boolean;
  accessPermission?: () => boolean;
  fallbackPath?: string;
}

const GuardedRoute: React.FC<GuardedRouteProps> = ({
  element,
  authGuard = false,
  accessPermission,
  fallbackPath = "/auth",
}) => {
  const token = localStorage.getItem("skillway-access-token");

  if (authGuard && !token) {
    return <Navigate to={fallbackPath} replace />;
  }

  if (accessPermission && !accessPermission()) {
    return <Navigate to={fallbackPath} replace />;
  }

  return element;
};

export default GuardedRoute;
