import GoogleAuth from "components/AuthDialog/Google";
import Button from "components/UI/Button";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as GoogleIcon } from "assets/google.svg";
import Input from "components/UI/Input";
import OtpInput from "react-otp-input";
import { useEffect, useLayoutEffect, useState } from "react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAuthStore from "store/authStore";
import { useNavigate } from "react-router-dom";
import { useToast } from "hooks/useToast";
import { useLoginGoogle, useOtpVerification, useSignIn } from "api/services";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
});

const Login = ({ onContinue }: any) => {
  const { showToast } = useToast();
  const { email, setEmail } = useAuthStore();

  const {
    data,
    refetch: getGoogleURL,
    isLoading: isGetGoogleURLLoading,
  } = useLoginGoogle({
    query: {
      enabled: false,
    },
  });

  const { mutate, isPending } = useSignIn();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: email || "" },
  });

  const googleAuthSubmit = async () => {
    try {
      const response = await getGoogleURL();
      console.log(response);

      console.log("Google login URL:", response.data);

      // @ts-ignore
      window.location.href = response?.data?.url;
    } catch (error) {
      console.error("Error fetching Google login URL:", error);
    }
  };

  const onSubmit = (data: { email: string }) => {
    setEmail(data.email);
    mutate(
      { data: { username: data.email } },
      {
        onSuccess: () => {
          showToast({
            title: "OTP Sent",
            description:
              "Your OTP has been sent successfully. Please check your email.",
            variant: "success",
          });
          onContinue();
        },
        onError: (error: any) => {
          showToast({
            title: "Error",
            description: error,
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <div className="animate-blur-to-normal">
      <h2 className="text-3xl font-bold text-gray-900 mb-4">
        Welcome
        <div>Let’s Get Started.</div>
      </h2>
      <div className="mb-4">
        <div className="text-gray-600 flex items-center">
          to continue to
          <div className="text-gray-900 font-bold ml-1">Skillway</div>
        </div>
      </div>

      <Button
        loading={isGetGoogleURLLoading}
        onClick={googleAuthSubmit}
        colorScheme="dark"
        fullWidth
        variant="outlined"
        beforeIcon={<GoogleIcon className="w-5 h-5" />}
        className="border-gray-400 flex items-center rounded-2xl justify-center"
      >
        <div className="flex">
          Continue with <div className="ml-1 font-bold">Google</div>
        </div>
      </Button>

      <div className="flex items-center justify-center text-gray-700 my-4">
        <span className="text-sm">Or</span>
      </div>

      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="email"
          placeholder="Email address"
          fullWidth
          {...register("email")} // Connect to react-hook-form
          className={`${errors.email ? "border-red-600 focus:border-red-600" : "border-gray-400"}`}
        />

        <Button
          disabled={isSubmitting}
          colorScheme="dark"
          fullWidth
          type="submit"
          loading={isPending}
        >
          Continue
        </Button>
      </form>
    </div>
  );
};

const OTP = ({ onBack }: any) => {
  const { showToast } = useToast();
  const { email } = useAuthStore();
  const navigate = useNavigate();

  const [countdown, setCountdown] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const schema = yup.object().shape({
    otp: yup
      .string()
      .required("OTP is required")
      .matches(/^\d{5}$/, "OTP must be 5 digits"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      otp: "", // Default value for the OTP field
    },
  });

  const { mutate, isPending } = useOtpVerification();
  const { mutate: resendOTP } = useSignIn();

  const onSubmit = (data: { otp: string }) => {
    if (email) {
      mutate(
        { data: { opt_code: data.otp, username: email } },
        {
          onSuccess: (response) => {
            const token = response.token;
            const isNewUser = response.is_new;

            if (token) {
              localStorage.setItem("skillway-access-token", token);
              if (isNewUser) {
                navigate("/profile-completion");
                return;
              }
              localStorage.setItem(
                "skillway-set-user-profile",
                JSON.stringify(false)
              );
              navigate("/app");
              showToast({
                title: "Welcome!",
                description: "You have successfully logged in.",
                variant: "success",
              });
            } else {
              console.error("No token found in response.");
            }
          },
        }
      );
    }
  };

  const resendCode = () => {
    setCountdown(60);
    setIsResendDisabled(true);
    if (email) {
      resendOTP(
        { data: { username: email } },
        {
          onSuccess: () => {
            showToast({
              title: "OTP Resent",
              description:
                "A new OTP has been sent successfully. Please check your email.",
              variant: "success",
            });
          },
          onError: (error: any) => {
            showToast({
              title: "OTP Resend Failed",
              description: "Failed to resend OTP. Please try again later.",
              variant: "error",
            });
          },
        }
      );
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendDisabled(false);
    }
  }, [countdown]);

  return (
    <div className="animate-blur-to-normal">
      <div className="text-xl font-normal text-gray-900 mb-6">
        We sent a code to
        <div>"{email}"</div>
      </div>

      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="otp"
          control={control}
          render={({ field }) => (
            <OtpInput
              shouldAutoFocus
              value={field.value}
              onChange={field.onChange}
              numInputs={5}
              renderInput={(props) => (
                <input
                  {...props}
                  className={`border ${
                    errors.otp ? "border-red-500" : "border-gray-400"
                  } focus:border-black focus:outline-none rounded-2xl w-10 h-10 text-center text-lg mx-1`}
                />
              )}
              inputStyle={{
                width: "100%",
                height: "50px",
              }}
            />
          )}
        />

        <Button colorScheme="dark" fullWidth type="submit" loading={isPending}>
          Continue
        </Button>

        <div className="text-sm">
          Didn’t get your code?{" "}
          {isResendDisabled ? (
            <span className="font-semibold">
              Send a new code in {countdown} secs
            </span>
          ) : (
            <span
              className="font-semibold cursor-pointer hover:opacity-75 underline decoration-dashed"
              onClick={resendCode}
            >
              Send a new code
            </span>
          )}
        </div>
        <span
          className="font-semibold cursor-pointer hover:opacity-75 underline decoration-dashed text-sm"
          onClick={onBack}
        >
          Change email address
        </span>
      </form>
    </div>
  );
};

const Auth = () => {
  const [showOTP, setShowOTP] = useState(false);

  return (
    <div className="flex min-h-screen items-center justify-center animate-gradient relative">
      <div className="z-10 absolute top-20 left-40 h-48 w-48 bg-gradient-to-r from-gray-700 via-gray-500 to-white blur-3xl opacity-50 animate-blur-shape"></div>
      <div className="absolute top-80 left-80 h-62 w-62 bg-gradient-to-r from-gray-700 via-gray-500 to-white blur-3xl opacity-50 animate-blur-shape"></div>
      <div className="absolute bottom-10 right-20 h-64 w-64 bg-gradient-to-r from-gray-800 via-gray-600 to-white blur-2xl opacity-30 animate-blur-shape"></div>
      <div className="absolute top-40 right-40 h-32 w-32 bg-gradient-to-r from-gray-900 via-gray-700 to-white blur-xl opacity-60 animate-blur-shape"></div>
      <div className="w-full max-w-5xl bg-white shadow-xl relative rounded-lg flex flex-col md:flex-row overflow-hidden z-30 animate-blur-to-normal">
        {/* Left Side */}
        <div className="w-full md:w-1/2 p-8 min-h-[410px]">
          {showOTP ? (
            <OTP onBack={() => setShowOTP(false)} />
          ) : (
            <Login onContinue={() => setShowOTP(true)} />
          )}
        </div>

        {/* Right Side */}
        <div className="w-full md:w-1/2 p-8 flex flex-col justify-center text-white bg-[#111827] space-y-4">
          <div>
            <h2 className="text-4xl font-normal">Skillway</h2>
            <h3 className="text-2xl font-semibold">Your Interview Pathway</h3>
          </div>

          <p className="text-lg">
            Lorem ipsum dolor sit amet consectetur. Volutpat pellentesque mauris
            laoreet mauris vitae egestas porta. Quam risus nullam vitae auctor
            nibh. Amet velit aliquam sodales interdum vel. Lectus ornare sed
            eget sagittis lacus.
          </p>

          <div className="flex items-center justify-start">
            {/* Circular Avatars */}
            <div className="flex -space-x-4">
              <div className="h-8 w-8 rounded-full bg-red-400 border"></div>
              <div className="h-8 w-8 rounded-full bg-blue-400 border"></div>
              <div className="h-8 w-8 rounded-full bg-green-400 border"></div>
              <div className="h-8 w-8 rounded-full bg-yellow-400 border"></div>
              <div className="h-8 w-8 rounded-full bg-purple-400 border"></div>
            </div>
            {/* Text */}
            <div className="ml-2">
              <p className="text-white text-xl font-medium">
                Trusted by +10,000 job seekers
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
