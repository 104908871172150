import GoogleAuth from "components/AuthDialog/Google";
import Button from "components/UI/Button";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ReactComponent as GoogleIcon } from "assets/google.svg";
import Input from "components/UI/Input";
import { useEffect, useState } from "react";
import { Select, Option, Radio } from "@material-tailwind/react";
import { countries } from "constants/countries";
import {
  AvailabilityPerWeek,
  Country,
  ExperienceLevel,
  Industry,
  JobStatus,
  PartialUpdateUserMetaDataRequest,
  useUpdateUserMetadata,
} from "api/services";
import { useToast } from "hooks/useToast";
import { useNavigate } from "react-router-dom";

type FieldBase = {
  type: "input" | "select" | "radio";
  name: string;
  label?: string;
  required: boolean;
};

type InputField = FieldBase & { type: "input" };
type SelectField<T> = FieldBase & { type: "select"; options: T[] };
type RadioField<T> = FieldBase & { type: "radio"; options: T[] };

type ProfileField =
  | InputField
  | SelectField<AvailabilityPerWeek | Country | Industry>
  | RadioField<AvailabilityPerWeek | ExperienceLevel | JobStatus>;

type ProfileCompletionStep = {
  title: string;
  description: string;
  fields: ProfileField[];
};

const steps: ProfileCompletionStep[] = [
  {
    title: "Welcome to Your Personalized Interview Coaching!",
    description:
      "Let's get to know you better so we can tailor your experience.",
    fields: [
      {
        type: "input",
        name: "first_name",
        label: "First Name",
        required: true,
      },
      { type: "input", name: "last_name", label: "Last Name", required: true },
      {
        type: "select",
        name: "country",
        label: "Country",
        options: Object.values(Country),
        required: true,
      },
    ],
  },
  {
    title: "Current Job Status",
    description: "What is your current job status?",
    fields: [
      {
        type: "radio",
        name: "job_status",
        options: Object.values(JobStatus),
        required: true,
      },
    ],
  },
  {
    title: "Experience Level",
    description: "What is your experience level?",
    fields: [
      {
        type: "radio",
        name: "experience_level",
        options: Object.values(ExperienceLevel),
        required: true,
      },
    ],
  },
  {
    title: "Role",
    description: "What is your role?",
    fields: [
      {
        type: "select",
        name: "industry",
        label: "Role",
        options: Object.values(Industry),
        required: true,
      },
    ],
  },
  {
    title: "Availability",
    description:
      "How many hours per week can you dedicate to interview preparation?",
    fields: [
      {
        type: "radio",
        name: "available_per_week",
        options: Object.values(AvailabilityPerWeek),
        required: true,
      },
    ],
  },
];

const ProfileCompletion = () => {
  const [step, setStep] = useState(0);
  const [animationClass, setAnimationClass] = useState("");
  const { showToast } = useToast();
  const navigate = useNavigate();

  const { mutate: updateUserProfile, isPending: isUpdateUserProfileLoading } =
    useUpdateUserMetadata();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PartialUpdateUserMetaDataRequest>();

  useEffect(() => {
    setAnimationClass("animate-blur-to-normal");
    setTimeout(() => setAnimationClass(""), 700);
  }, []);

  const onSubmit: SubmitHandler<PartialUpdateUserMetaDataRequest> = (data) => {
    setAnimationClass("animate-blur-to-normal");
    setTimeout(() => setAnimationClass(""), 700);

    if (step === steps.length - 1) {
      updateUserProfile(
        { data },
        {
          onSuccess: () => {
            localStorage.setItem(
              "skillway-set-user-profile",
              JSON.stringify(true)
            );
            navigate("/app");

            showToast({
              title: "Registration Successful",
              description:
                "Welcome aboard! Your registration has been completed successfully.",
              variant: "success",
            });
          },
          onError: () => {
            showToast({
              title: "Registration Failed",
              description:
                "Something went wrong while processing your registration.",
              variant: "error",
            });
          },
        }
      );

      console.log("Form Data Submitted:", data);
      // Call API here
    } else {
      // Move to the next step
      setStep((prevStep) => prevStep + 1);
    }
    console.log(step);
  };

  const handleBack = () => {
    setAnimationClass("animate-blur-to-normal");
    setTimeout(() => setAnimationClass(""), 700); // Res
    if (step > 0) setStep((prevStep) => prevStep - 1);
  };

  const renderField = (field: any) => {
    switch (field.type) {
      case "input":
        return (
          <Input
            key={field.name}
            fullWidth
            placeholder={field.label}
            {...register(
              field.name,
              field.required && { required: `${field.label} is required` }
            )}
          />
        );
      case "select":
        return (
          <Controller
            key={field.name}
            name={field.name}
            control={control}
            rules={field.required && { required: `${field.label} is required` }}
            render={({ field: controllerField }) => (
              <Select
                {...controllerField}
                label={field.label}
                onChange={(value) => controllerField.onChange(value)}
                value={controllerField.value || ""}
                className="max-h-64 overflow-y-auto z-50"
              >
                {field.options.map((option: any) => (
                  <Option
                    key={option.countryCode || option}
                    value={option.countryCode || option}
                  >
                    {option.name || option}
                  </Option>
                ))}
              </Select>
            )}
          />
        );
      case "radio":
        return (
          <div key={field.name} className="flex flex-col">
            {field.options.map((option: string) => (
              <Radio
                crossOrigin=""
                key={option}
                label={option}
                {...register(
                  field.name,
                  field.required && { required: "This field is required" }
                )}
                value={option}
              />
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center animate-gradient relative">
      <div className="z-10 absolute top-20 left-40 h-48 w-48 bg-gradient-to-r from-gray-700 via-gray-500 to-white blur-3xl opacity-50 animate-blur-shape"></div>
      <div className="absolute top-80 left-80 h-62 w-62 bg-gradient-to-r from-gray-700 via-gray-500 to-white blur-3xl opacity-50 animate-blur-shape"></div>
      <div className="absolute bottom-10 right-20 h-64 w-64 bg-gradient-to-r from-gray-800 via-gray-600 to-white blur-2xl opacity-30 animate-blur-shape"></div>
      <div className="absolute top-40 right-40 h-32 w-32 bg-gradient-to-r from-gray-900 via-gray-700 to-white blur-xl opacity-60 animate-blur-shape"></div>
      <div
        className={`w-full max-w-screen-xl justify-center relative rounded-lg flex flex-col md:flex-row overflow-hidden z-30 ${animationClass}`}
      >
        <div className="w-full md:w-1/2 p-8 space-y-4">
          <div className="space-x-1">
            <span className="text-4xl font-bold">{step + 1}</span>
            <span>/</span>
            <span className="text-3xl font-extralight">{steps.length}</span>
          </div>
          <h2 className="text-3xl font-bold text-gray-900">
            {steps[step].title}
          </h2>
          <p className="text-xl">{steps[step].description}</p>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            {steps[step].fields.map((field) => renderField(field))}
            <div className="flex gap-2 mt-6">
              <Button
                type="submit"
                color="blue"
                loading={isUpdateUserProfileLoading}
              >
                {step === steps.length - 1 ? "Submit" : "Continue"}
              </Button>
              {step > 0 && (
                <Button type="button" variant="outlined" onClick={handleBack}>
                  Back
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileCompletion;
